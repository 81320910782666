@font-face {
    font-family: 'Monofonto';
    src: local('Monofonto'), url(../font/monofonto.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(../font/Nunito-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito-Regular';
    src: local('Nunito-Regular'), url(../font/Nunito-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito-Bold';
    src: local('Nunito-Bold'), url(../font/Nunito-Bold.ttf) format('truetype');
}

h1, h2, h3, h4, h5, h6, a, p {
    font-family: 'Nunito', serif;
}